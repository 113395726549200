/**
 * Main JavaScript file
 *
 * @package RootPixel
 * @subpackage StripeSmoothNavigation
 * @since StripeSmoothNavigation 1.0.0
 */

import "../scss/index.scss";

document.addEventListener("DOMContentLoaded", () => {

    let menuLink = $('.menu_dp-link');
    let content = $('.menu_dropdown_content');
    let menuBG = $('.menu_bg');
    let dropdownWrap = $('.menu_content');
    let menuArrow = $('.menu_arrow-wrap');

    function switchDropdown(currentLink, previousContent, currentContent) {
        gsap.to(menuArrow, {
            width: currentLink.outerWidth(),
            x: currentLink.offset().left
        });

        gsap.to(menuBG, {
            width: currentContent.outerWidth(),
            height: currentContent.outerHeight()
        });

        let moveDistance = 10;

        if (currentContent.index() < previousContent.index()) {
            moveDistance = moveDistance * -1;
        }

        gsap.fromTo( previousContent, 
            {
                opacity: 1,
                x: "0rem"
            },
            {
                opacity: 0,
                x: -1 * moveDistance + "rem",
                duration: 0.3
            }
        );

        gsap.fromTo( currentContent, 
            {
                opacity: 0,
                x: moveDistance + "rem"
            },
            {
                opacity: 1,
                x: "0",
                duration: 0.3
            }
        );
    }

    let showDropdown = gsap.timeline({
        onReverseComplete: () => {
            dropdownWrap.css("display", "none");
            menuLink.removeClass("active");
        }
    });
    showDropdown.from(
        dropdownWrap, { 
            opacity: 0, 
            rotateX: -10, 
            duration: 0.2
        }
    ).to(
        menuArrow,
        {
            opacity: 1,
            duration: 0.2
        },
        "<"
    );    

    function revealDropdown(currentLink, currentContent) {
        dropdownWrap.css("display", "flex");
        showDropdown.restart();

        gsap.set(menuArrow, {
            width: currentLink.outerWidth(),
            x: currentLink.offset().left
        });

        gsap.set(menuBG, {
            width: currentContent.outerWidth(),
            height: currentContent.outerHeight()
        });

        gsap.set(content, {
            opacity: 0
        });

        gsap.set(currentContent, {
            opacity: 1,
            x: "0rem"
        });
    }

    // Link Hover in
    menuLink.on("mouseenter", function() {
        // get elements
        let previousLink = menuLink.filter(".active").removeClass("active");
        let currentLink = $(this).addClass("active");
        let previousContent = content.filter(".active").removeClass("active");
        let currentContent = content.eq($(this).index()).addClass("active");

        // play animations
        if (previousLink.length > 0) {
            switchDropdown(currentLink, previousContent, currentContent);
        } else {
            revealDropdown(currentLink, currentContent);
        }
    });

    $(".menu_dp-wrap").on("mouseleave", function() {
        showDropdown.reverse();
    })
});
/*

Vanilla JS solution - TODO hide the previous content

document.addEventListener("DOMContentLoaded", () => {
    const menuLinks = document.querySelectorAll('.menu_dp-link');
    const contents = document.querySelectorAll('.menu_dropdown_content');
    const menuBG = document.querySelector('.menu_bg');
    const dropdownWrap = document.querySelector('.menu_content');
    const menuArrow = document.querySelector('.menu_arrow-wrap');
  
    let activeLink = null;
    let activeContent = null;
  
    function switchDropdown(currentLink, previousContent, currentContent) {
      const currentLinkWidth = currentLink.offsetWidth;
      const currentLinkOffset = currentLink.getBoundingClientRect().left;
      const currentContentWidth = currentContent.offsetWidth;
      const currentContentHeight = currentContent.offsetHeight;
  
      gsap.to(menuArrow, {
        width: currentLinkWidth,
        x: currentLinkOffset,
      });
  
      gsap.to(menuBG, {
        width: currentContentWidth,
        height: currentContentHeight,
      });
  
      let moveDistance = 10;
  
      if (
        Array.from(contents).indexOf(currentContent) <
        Array.from(contents).indexOf(previousContent)
      ) {
        moveDistance = moveDistance * -1;
      }
  
      gsap.fromTo(
        previousContent,
        {
          opacity: 1,
          x: "0rem",
        },
        {
          opacity: 0,
          x: -1 * moveDistance + "rem",
          duration: 0.3,
        }
      );
  
      gsap.fromTo(
        currentContent,
        {
          opacity: 0,
          x: moveDistance + "rem",
        },
        {
          opacity: 1,
          x: "0",
          duration: 0.3,
        }
      );
    }
  
    const showDropdown = gsap.timeline({
      onReverseComplete: () => {
        dropdownWrap.style.display = "none";
        if (activeLink) {
          activeLink.classList.remove("active");
        }
      },
    });
  
    showDropdown
      .from(dropdownWrap, {
        opacity: 0,
        rotateX: -10,
        duration: 0.2,
      })
      .to(
        menuArrow,
        {
          opacity: 1,
          duration: 0.2,
        },
        "<"
      );
  
    function revealDropdown(currentLink, currentContent) {
      dropdownWrap.style.display = "flex";
      showDropdown.restart();
  
      const currentLinkWidth = currentLink.offsetWidth;
      const currentLinkOffset = currentLink.getBoundingClientRect().left;
      const currentContentWidth = currentContent.offsetWidth;
      const currentContentHeight = currentContent.offsetHeight;
  
      gsap.set(menuArrow, {
        width: currentLinkWidth,
        x: currentLinkOffset,
      });
  
      gsap.set(menuBG, {
        width: currentContentWidth,
        height: currentContentHeight,
      });
  
      Array.from(contents).forEach((content) => {
        content.style.opacity = 0;
      });
  
      const delay = activeLink ? 0.2 : 0;
      
      setTimeout(() => {
        currentContent.style.opacity = 1;
        currentContent.style.x = "0rem";
  
        if (activeLink) {
          activeLink.classList.remove("active");
        }
        currentLink.classList.add("active");
        activeLink = currentLink;
  
        if (activeContent) {
          activeContent.classList.remove("active");
        }
        currentContent.classList.add("active");
        activeContent = currentContent;
      }, delay);
    }
  
    Array.from(menuLinks).forEach((link) => {
      link.addEventListener("mouseenter", function () {
        const previousLink = activeLink;
        const currentLink = this;
        const previousContent = activeContent;
        const currentContent = contents[Array.from(menuLinks).indexOf(currentLink)];
  
        if (previousLink) {
          switchDropdown(currentLink, previousContent, currentContent);
        } else {
          revealDropdown(currentLink, currentContent);
        }
      });
    });
  
    const menuWrap = document.querySelector(".menu_dp-wrap");
    menuWrap.addEventListener("mouseleave", function () {
      showDropdown.reverse();
    });
  });*/